import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import background from '../media/images/poopsmal.PNG';
import { FaYoutube } from 'react-icons/fa';

function HomePage() {
  const [latestVideo, setLatestVideo] = useState(null);
  const API_KEY = 'AIzaSyDFQe1F__mF8tRoMEqGhc06qx6Dnb8brmA'; // Replace with your YouTube Data API key
  const CHANNEL_ID = 'UCAAbZeiuPwsbKqiD8QBubxw'; // Replace with your YouTube Channel ID

  useEffect(() => {
    async function fetchLatestVideo() {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=1`
        );
        const data = await response.json();
        
        if (data.items && data.items.length > 0) {
          const videoId = data.items[0].id.videoId; // Get the videoId of the latest video
          setLatestVideo(`${videoId}`);
        } else {
          console.error('No videos found for the channel.');
        }
      } catch (error) {
        console.error('Error fetching latest video:', error);
      }
    }

    fetchLatestVideo();
  }, []);

  return (
    <div className="min-h-screen bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-white relative">
      <div className="flex h-96 justify-center items-center" style={{ backgroundImage: `url(${background})`, backgroundSize: '' }}>
        <div className="flex bg-white/20 backdrop-blur-lg justify-center items-center w-full h-full">
          <div className="flex flex-col justify-center items-center text-white">
            <h1 className="text-4xl font-bold mb-4 text-center">Spoekle.com</h1>
            <p className="text-2xl text-center">Work in progress...</p>
          </div>
        </div>
      </div>
      <div class="absolute inset-0 bg-center filter blur-lg" style={{ backgroundImage: `url(${background})`, backgroundSize: '' }}></div>
            <div class="absolute inset-0 bg-gradient-to-b from-neutral-950/30 to-neutral-950"></div>
            <div class="relative text-center z-10">
                <h2 class="font-semibold text-white drop-shadow-md text-5xl md:scale-150 transition ease-in-out duration-300 leading-tight uppercase">
                    Spoekle's Hub
                </h2>
                <p class="text-2xl text-white drop-shadow-md md:scale-125 transition ease-in-out duration-200 mt-4">
                    Welcome, to all things Spoekle.
                </p>
            </div>
      <div className="flex-grow flex flex-col p-4 pt-8 bg-neutral-200 dark:bg-neutral-900 transition duration-200 justify-center items-center">
        <div className="md:container grid grid-cols-1 md:grid-cols-2 justify-center items-center w-full h-full">
          <div className="flex flex-col justify-center items-center m-4 p-4 bg-neutral-300 dark:bg-neutral-950 rounded-lg">
            <h1 className="text-6xl font-bold m-4 text-center">Heya! I'm Spoekle</h1>
            <div className="flex flex-col justify-between mt-8">
              <h1 className="text-xl font-bold m-4 text-left">Heya! I'm Spoekle</h1>
            </div>
          </div>
          <div className='w-full h-auto aspect-video m-4'>
            
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col py-8 bg-neutral-200 dark:bg-neutral-900 transition duration-200 justify-center items-center">
        <div className="container grid grid-cols-1 lg:grid-cols-2 justify-center items-center w-full h-full">
          <div className='w-full h-auto aspect-video'>
            {latestVideo ? (
              <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${latestVideo}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Latest YouTube Video"
                className="sm:rounded-none lg:rounded-l-xl"
              ></iframe>
            ) : (
              <div className="flex w-full h-full justify-center items-center bg-neutral-300 dark:bg-neutral-700 sm:rounded-none lg:rounded-l-xl animate-pulse">
                <h1 className="text-3xl text-center">Latest video not available...</h1>
              </div>
            )}
          </div>
          <div className="flex h-full flex-col justify-center items-center bg-neutral-300 dark:bg-neutral-950 sm:rounded-none lg:rounded-r-lg">
            <h1 className="text-3xl font-semibold m-4 text-center">Watch the latest video here!</h1>
            <div className="flex flex-col justify-between my-8">
              <Link to="https://www.youtube.com/@Spoekle">
                <button className="bg-red-500 text-white hover:text-red-500 hover:bg-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-300">
                  YouTube <FaYoutube className="inline" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
